/**
 * @render react
 * @name Header
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useLayout } from 'Helpers/hooks/useLayout';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation'; // Button, NavigationItem (Icon and Link)
import { ProductSelector } from '@autovia-uk/polaris-components/components/molecules/ProductSelector';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch'; // Button, Input, Form, Label

import './_style.scss';

// Check for the "commercial-homepage" query parameter
const isCommercialHomepageQueryParam = () => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('commercial-homepage') !== null;
  }
  return false;
};

const Header = (props) => {
  const {
    context: {
      cssPrefix,
      header: {
        navigation,
      },
      branding: {
        logoImage,
      },
    },
    config: {
      login: {
        accountMenu,
      },
      productSelector: {
        popularInputs,
        popularInputsLabel,
      },
    },
    isHomeTemplate,
    layoutType,
  } = props;
  const searchRef = React.createRef();
  const [isOpen, setOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCommercialHomepage, setIsCommercialHomepage] = useState(false);

  const {
    headerLandingPageClass,
    hideHeaderNavigation,
    hideProductSelector,
  } = useLayout(layoutType);

  const onClick = () => {
    // trigger menu open/close only on mobile and tablet
    // source https://github.com/carwow/polaris-componentlibrary/blob/master/components/molecules/NavigationItem/NavigationItem.js#L81
    const breakpoint = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/"/g, '');

    if (breakpoint === 'mobile' || breakpoint === 'tablet') {
      setOpen(!isOpen);
      document.documentElement.classList.toggle('-menu-open');
    }
  };

  const onSearchClick = () => {
    setSearchOpen(!isSearchOpen);
    setOpen(false);
    document.documentElement.classList.toggle('-search-open');
    document.documentElement.classList.remove('-menu-open');
  };

  const onSearchSubmit = () => {
    onSearchClick();
  };

  /**
   * Pushes tracking data to the dataLayer array.
   *
   * @param {Object} trackingData - The tracking data to be pushed to the dataLayer.
   */
  const onPromoBlockLinkClick = (trackingData) => {
    window.dataLayer = window.dataLayer || [];
    if (trackingData) {
      window.dataLayer.push(trackingData);
    }
  };

  /**
   * Creates tracking data object for marketplace button clicks.
   *
   * @param {string} type - The type of button (e.g., 'GYC', 'SMC').
   * @param {string} placement - Where the button is placed (e.g., 'GYCHeaderPromoBlock').
   * @returns {Object} Tracking data object with event details.
   */
  const createTrackingData = (type, placement) => ({
    event: 'marketplace.button_click',
    'event.type': type,
    'marketplace_button.placement': placement,
  });

  const TRACKING_DATA = {
    GYC: createTrackingData('GYC', 'GYCHeaderPromoBlock'),
    SMC: createTrackingData('SMC', 'SMCHeaderPromoBlock'),
    NAV_GYC: createTrackingData('GYC', 'GYCNavigationPromoLink'),
    NAV_SMC: createTrackingData('SMC', 'SMCNavigationPromoLink'),
    COMMERCIAL_HP_GYC: createTrackingData('GYC', 'GYCCommercialHomepageLink'),
    COMMERCIAL_HP_SMC: createTrackingData('SMC', 'SMCCommercialHomepageLink'),
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && isSearchOpen) {
      if (searchRef && searchRef.current) {
        const inputEl = searchRef.current.querySelector('input[type="search"]');
        setTimeout(() => {
          inputEl.focus();
        }, 0);
      }
    }
  }, [isSearchOpen]);

  // On login click, check if cookie signed_in is false and redirect to login page
  const onLogInClick = () => {
    if (!isLoggedIn) {
      window.location.href = 'https://marketplace.autoexpress.co.uk/users/sign_in';
    }
  };

  const onLoggedInClick = () => {
    setAccountOpen(!isAccountOpen);
    document.documentElement.classList.toggle('-account-menu-open');
  };

  // If cookie signed_in is true, set isLoggedIn to true
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const signedIn = document.cookie.split(';').find(row => row.trim().startsWith('signed_in='));
      setIsLoggedIn(signedIn && signedIn.split('=')[1] === '1');

      // Track if the user is logged in
      window.dataLayer.push(
        {
          event: 'logged-in',
          LoggedIn: (signedIn && signedIn.split('=')[1] === '1') || false,
        },
      );
    }
  }, []);

  // remove empty objects from navigation
  const filteredNavigation = navigation && navigation.filter(value => Object.keys(value).length !== 0);

  const navigationExtraContent = extraContentClassName => (
    <div
      className={
        `${cssPrefix}__header--navigation--extra-content`
        + ` ${extraContentClassName}`
      }
    >
      <div className={`${cssPrefix}__header--promo-title`}>
        How can we help you?
      </div>
      <div className={`${cssPrefix}__header--promo-links`}>
        <Link
          href="/buy-a-car"
          extraClassNames={{
            [`${cssPrefix}__header--promo-link`]: true,
            '-filled': true,
          }}
          onClick={() => onPromoBlockLinkClick(TRACKING_DATA.NAV_GYC)}
        >
          Buy a Car
        </Link>
        <Link
          href="/sell-my-car"
          extraClassNames={{
            [`${cssPrefix}__header--promo-link`]: true,
            '-sheer-white': true,
          }}
          onClick={() => onPromoBlockLinkClick(TRACKING_DATA.NAV_SMC)}
        >
          Sell My Car
        </Link>
      </div>
    </div>
  );

  useEffect(() => {
    setIsCommercialHomepage(isHomeTemplate && isCommercialHomepageQueryParam());
  }, [isHomeTemplate]); // Only re-run when isHomeTemplate changes

  return (
    <div className={`polaris__header--wrapper${isCommercialHomepage ? ' -commercial-homepage' : ''}`}>
      <div className="screen-reader-text">
        <a href="#main">Skip to Content</a>
        <a href="#footer">Skip to Footer</a>
      </div>
      <header
        className={classNames({
          [`${cssPrefix}__header`]: true,
          [`${cssPrefix}__header-landing`]: headerLandingPageClass,
        })}
        id="header"
      >
        <div
          className={classNames({
            [`${cssPrefix}__header--inner`]: true,
            '-is-menu-open': isOpen,
            '-is-search-open': isSearchOpen,
          })}
        >
          <div
            className={classNames({
              [`${cssPrefix}__header--logo`]: true,
            })}
          >
            <Link href="/">
              <Image
                src={logoImage.src}
                lazyLoad={false}
              />
            </Link>
          </div>

          {!hideHeaderNavigation && (
            <div
              className={classNames({
                [`${cssPrefix}__header--navigation-search`]: true,
              })}
            >
              <div
                className={classNames({
                  [`${cssPrefix}__header--search`]: true,
                })}
                ref={searchRef}
              >
                <SimpleSearch
                  label=""
                  placeholder="start typing to search"
                  url="/search"
                  submitLabel=""
                  closeOnClick={onSearchClick}
                  onSubmit={onSearchSubmit}
                  responsive
                />
                <Button
                  type="button"
                  label="Search"
                  srLabel="Search"
                  variant=""
                  onClick={onSearchClick}
                  extraClassNames={{
                    [`${cssPrefix}__header--search-button`]: true,
                  }}
                />
              </div>

              <div
                className={classNames({
                  [`${cssPrefix}__header--login`]: true,
                })}
              >
                {isLoggedIn ? (
                  <>
                    <Button
                      type="button"
                      label="Account"
                      srLabel="Account"
                      variant=""
                      onClick={onLoggedInClick}
                      extraClassNames={{
                        [`${cssPrefix}__header--account-button`]: true,
                      }}
                    />
                    <Navigation
                      id="polaris__account-menu"
                      extraClassNames={{
                        [`${cssPrefix}__header--account-menu`]: true,
                      }}
                      content={accountMenu}
                      onButtonClick={onLoggedInClick}
                      navigationClassName={`${cssPrefix}__header--navigation`}
                      buttonLabel={isAccountOpen ? 'Close' : 'Account'}
                      buttonClassName={`${cssPrefix}__header--menu-button`}
                      subMenuIcon={`${cssPrefix}__ico-down`}
                      responsive
                      responsiveNavHeading="Account"
                      activeWildcard={false}
                      subMenuClickBreakpoint="tablet"
                    />
                  </>
                ) : (
                  <Button
                    type="button"
                    label="Log in"
                    srLabel="Log in"
                    variant=""
                    onClick={onLogInClick}
                    extraClassNames={{
                      [`${cssPrefix}__header--login-button`]: true,
                    }}
                  />
                )}
              </div>

              {filteredNavigation && (
                <>
                  <Button
                    type="button"
                    label="Menu"
                    srLabel="Menu"
                    variant=""
                    onClick={onClick}
                    extraClassNames={{
                      [`${cssPrefix}__header--menu-button`]: true,
                    }}
                  />
                  <Navigation
                    id="polaris__top-menu"
                    content={filteredNavigation}
                    onButtonClick={onClick}
                    navigationClassName={`${cssPrefix}__header--navigation`}
                    buttonLabel={isOpen ? 'Close' : 'Menu'}
                    buttonClassName={`${cssPrefix}__header--menu-button`}
                    subMenuIcon={`${cssPrefix}__ico-caret_right`}
                    responsive
                    responsiveNavHeading="Menu"
                    activeWildcard={false}
                    showSubMenuTitle
                    subMenuClickBreakpoint="tablet"
                    extraContent={navigationExtraContent(`${cssPrefix}__header--navigation--extra-content--mobile`)}
                    viewAllLabel="All "
                    extraSubMenuContent={navigationExtraContent(`${cssPrefix}__header--navigation--extra-content--desktop`)}
                  />
                </>
              )}

            </div>
          )}
        </div>
      </header>

      {isHomeTemplate && (
        isCommercialHomepage ? ( // if commercial homepage
          <div className={`${cssPrefix}__header--commercial-homepage--promo-block`}>
            <p className={`${cssPrefix}__header--commercial-homepage--title`}>Where trust means a great deal</p>
            <div className={`${cssPrefix}__header--commercial-homepage--ctas`}>
              <Link
                href="https://marketplace.autoexpress.co.uk/buy-a-car"
                extraClassNames={{
                  [`${cssPrefix}__header--commercial-homepage--cta`]: true,
                }}
                onClick={async () => { await asyncTrackLinkClick(TRACKING_DATA.COMMERCIAL_HP_GYC); }}
              >
                Find a car
              </Link>
              <Link
                href="/sell-my-car"
                extraClassNames={{
                  [`${cssPrefix}__header--commercial-homepage--cta`]: true,
                }}
                onClick={() => onPromoBlockLinkClick(TRACKING_DATA.COMMERCIAL_HP_SMC)}
              >
                Sell My Car
              </Link>
            </div>
          </div>
        ) : ( // if not commercial homepage
          <div className={`${cssPrefix}__header--promo-block`}>
            <Link
              href="/buy-a-car"
              extraClassNames={{
                [`${cssPrefix}__header--promo-block--gyc`]: true,
              }}
              onClick={() => onPromoBlockLinkClick(TRACKING_DATA.GYC)}
            >
              Buy a Car
            </Link>
            <Link
              href="/car-valuation"
              extraClassNames={{
                [`${cssPrefix}__header--promo-block--smc`]: true,
              }}
              onClick={() => onPromoBlockLinkClick(TRACKING_DATA.SMC)}
            >
              Sell My Car
            </Link>
          </div>
        )
      )}
      {!hideProductSelector && (
        <div className={`${cssPrefix}__header--product-selector--wrapper`}>
          <ProductSelector
            extraClassNames={{
              [`${cssPrefix}__header--product-selector`]: true,
              [`${cssPrefix}__header--commercial-homepage--product-selector`]: isCommercialHomepage,
            }}
            groupOptions
            popularInputs={popularInputs}
            popularInputsLabel={popularInputsLabel}
            title={isCommercialHomepage ? 'Find a review' : 'Find a car review'}
          />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  context: PropTypes.shape().isRequired,
  config: PropTypes.shape().isRequired,
  isHomeTemplate: PropTypes.bool,
  layoutType: PropTypes.string,
};

Header.defaultProps = {
  isHomeTemplate: false,
  layoutType: '',
};

export default withPolaris(Header);
